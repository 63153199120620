exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-form-submitted-tsx": () => import("./../../../src/pages/contact-form-submitted.tsx" /* webpackChunkName: "component---src-pages-contact-form-submitted-tsx" */),
  "component---src-pages-download-guide-tsx": () => import("./../../../src/pages/download-guide.tsx" /* webpackChunkName: "component---src-pages-download-guide-tsx" */),
  "component---src-pages-easy-invoices-tsx": () => import("./../../../src/pages/easy-invoices.tsx" /* webpackChunkName: "component---src-pages-easy-invoices-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lead-form-submitted-tsx": () => import("./../../../src/pages/lead-form-submitted.tsx" /* webpackChunkName: "component---src-pages-lead-form-submitted-tsx" */),
  "component---src-pages-post-tsx": () => import("./../../../src/pages/post.tsx" /* webpackChunkName: "component---src-pages-post-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-sf-education-cloud-tsx": () => import("./../../../src/pages/sf-education-cloud.tsx" /* webpackChunkName: "component---src-pages-sf-education-cloud-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

